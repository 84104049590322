import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';

import Footer from '../components/Footer';




const JobsPage = () => (
  <Layout>
    <Header />


    <section className="jobs text-center" id="jobs">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <h2 className="section-heading">
            TOP 7 FIND ALL 7 ANSWERS
            </h2>
            <p>
              Our app is available on any mobile device! Download now to get
              started!
            </p>
            
          </div>

        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default JobsPage;
